.confirmation-code-inputs {
  display: flex;
  justify-content: center;
  margin: 2rem;

  input[type="number"] {
    width: 15%;
    font-size: 5em;
    height: 1.2em;
    line-height: 1;
    padding: 0;
    margin: 0 2%;
    text-align: center;
    box-shadow: 1px 3px 3px #bbb;
    -moz-appearance: textfield;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}
